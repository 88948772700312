import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { flagIcon, getUltraMessageStatus } from '../../constants/Helpers';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MaterialDataGrid from '../../components/shared/MaterialDataGrid';
import AppErrorBoundary from '../shared/AppErrorBoundary';
import ProcessingLoader from '../shared/ProcessingLoader';
import AppService from '../../services/AppService';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const UndeliveredModal = ({ isPopupOpen, setIsPopUpOpen, occasionId }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [clonedUndeliveredData, setClonedUndeliveredData] = useState([]);
  const [typeValue, setTypeValue] = useState(2);
  const [payload, setPayload] = useState([]);

  const columns = [
    {
      title: 'الاسم',
      field: 'name',
    },
    {
      title: 'رقم التليفون',
      field: 'phone',
    },
    {
      title: 'الرسالة النصيه',
      render: (rowData) =>
        rowData?.messageType === 0 ? (
          <img style={{ width: 30 }} src={flagIcon.falseIcon} />
        ) : (
          <span>-</span>
        ),
    },
    {
      title: 'رسالة البرشور ',
      render: (rowData) =>
        rowData?.messageType === 1 ? (
          <img style={{ width: 30 }} src={flagIcon.falseIcon} />
        ) : (
          <span>-</span>
        ),
    },
    {
      title: 'الباركود ',
      render: (rowData) => barCodeAttachments(rowData),
    },
    {
      title: 'لينك الاعتذار',
      render: (rowData) => (
        <a href={`https://dawabarcode.com/apologize/${rowData?.token}`} target="_blank">
          افتح
        </a>
      ),
    },
    {
      title: 'اجمالي الدعوات',
      render: (rowData) => <span>{rowData?.count}</span>,
    },
    {
      title: 'حالة التأكيد',
      render: (rowData) => (
        <span>{!rowData?.isTokenUsed ? 'غير محدد' : rowData?.isRejected ? 'معتذر' : 'تأكيد'}</span>
      ),
    },
    {
      title: 'حالة الارسال',
      render: (rowData) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {rowData?.ultraMsgStatus === 1 && (
            <img style={{ width: 23, marginLeft: 4 }} src={flagIcon.trueIcon} />
          )}
          {rowData?.ultraMsgStatus === 0 && <img style={{ width: 23 }} src={flagIcon.falseIcon} />}
          <p>{getUltraMessageStatus[rowData?.ultraMsgStatus]}</p>
        </div>
      ),
    },
    {
      title: 'Ultra message ',
      render: (rowData) => (
        <Button
          style={{ margin: 5, backgroundColor: '#22af47', color: 'white' }}
          variant="contained"
          endIcon={<WhatsAppIcon style={{ marginRight: 7 }} />}
          onClick={() => handleUltraMessage(rowData)}
        ></Button>
      ),
    },

    {
      title: 'WhatsApp web',
      render: (rowData) => (
        <Button
          style={{ margin: 5, backgroundColor: '#006782', color: 'white' }}
          variant="contained"
          endIcon={<WhatsAppIcon style={{ marginRight: 7 }} />}
          onClick={() => openWhatsApp(rowData)}
        ></Button>
      ),
    },
  ];

  const barCodeAttachments = (rowData) => {
    return rowData?.qrCodes?.map((el, i) => (
      <a
        key={i}
        style={{ display: 'block' }}
        href={el?.brochureQrCodeUrl}
        target="_blank"
        rel="noreferrer"
        download
      >
        {el?.qrCode}
      </a>
    ));
  };

  const openWhatsApp = (rowData) => {
    const url = `https://web.whatsapp.com/send?phone=${
      rowData?.phone
    }&text=${rowData?.message?.replace(/\n/g, '%0A')}`;
    window.open(url, '_blank');
  };

  const handleUltraMessage = async (rowData) => {
    setIsLoading(true);
    const data = [
      {
        id: rowData?.id,
        phoneNumber: rowData?.phone,
        type: rowData?.messageType,
        message: rowData?.messageType === 0 ? rowData?.message : null,
        qrCodes:
          rowData?.messageType === 1
            ? rowData?.qrCodes?.map((el) => ({
                brochureQrCodeUrl: el?.brochureQrCodeUrl,
                id: el?.id,
              }))
            : null,
      },
    ];
    try {
      const res = await AppService.getSendUltraMessage(data);
      setIsLoading(false);
      toast.success(res?.data?.message ?? 'تم الارسال بنجاح');
      setTimeout(() => {
        fetchUndeliveredNumbers(occasionId);
      }, 3000);
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'فشل في الارسال ');
      setIsLoading(false);
    }
  };

  const handleUltraBulkMessages = async () => {
    setIsLoading(true);
    try {
      const res = await AppService.getSendUltraMessage(payload);
      setIsLoading(false);
      toast.success(res?.data?.message ?? 'تم الارسال بنجاح');
      setTimeout(() => {
        fetchUndeliveredNumbers(occasionId);
      }, 3000);
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'فشل في الارسال ');
      setIsLoading(false);
    }
  };

  const options = {
    paging: false,
    showTitle: false,
    columnsButton: false,
    sorting: false,
    exportButton: false,
    selection: true,
  };

  const fetchUndeliveredNumbers = async (id) => {
    setIsLoading(true);
    try {
      const res = await AppService.getUndeliveredNumbers(id);
      setData(res?.data);
      setClonedUndeliveredData(res?.data);
      setIsLoading(false);
    } catch (e) {
      toast.error(e?.response?.data?.message ?? 'فشل في الحصول علي الارقام');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUndeliveredNumbers(occasionId);
  }, []);

  //actions
  const actions = [
    {
      icon: () => (
        <Box style={{ overflow: 'auto' }}>
          <Tabs
            indicatorColor="secondary"
            textColor="black"
            value={typeValue}
            onChange={handleChangeType}
            centered
            style={{ overflow: 'auto' }}
          >
            <Tab
              label={
                <>
                  <span style={{ fontWeight: 'bold', color: 'black' }}>اجمالي الغير مرسل</span>
                  <p style={{ fontWeight: 'bold', color: 'black' }}>
                    {clonedUndeliveredData?.length}
                  </p>
                </>
              }
              value={2}
            />
            <Tab
              label={
                <>
                  <span style={{ fontWeight: 'bold', color: 'black' }}>استلام</span>
                  <p style={{ fontWeight: 'bold', color: 'black' }}>
                    {clonedUndeliveredData?.filter((el) => el?.ultraMsgStatus === 1)?.length}
                  </p>
                </>
              }
              value={1}
            />
            <Tab
              label={
                <>
                  <span style={{ fontWeight: 'bold', color: 'black' }}>عدم استلام</span>
                  <p style={{ fontWeight: 'bold', color: 'black' }}>
                    {clonedUndeliveredData?.filter((el) => el?.ultraMsgStatus === 0)?.length}
                  </p>
                </>
              }
              value={0}
            />
          </Tabs>
        </Box>
      ),

      isFreeAction: true,
    },
  ];

  const handleChangeType = (e, newValue) => {
    setTypeValue(newValue);
    if (newValue === 2) setData(clonedUndeliveredData);
    if (newValue === 1) setData(clonedUndeliveredData?.filter((el) => el?.ultraMsgStatus === 1));
    else if (newValue === 0)
      setData(clonedUndeliveredData?.filter((el) => el?.ultraMsgStatus === 0));
  };

  const onSelectionChange = (selectedrecords) => {
    const newPayload = selectedrecords.map((el) => ({
      id: el?.id,
      phoneNumber: el?.phone,
      type: el?.messageType,
      message: el?.messageType === 0 ? el?.message : null,
      qrCodes:
        el?.messageType === 1
          ? el?.qrCodes?.map((el) => ({
              brochureQrCodeUrl: el?.brochureQrCodeUrl,
              id: el?.id,
            }))
          : null,
    }));
    setPayload(newPayload);
  };

  return (
    <AppErrorBoundary>
      <Dialog
        onClose={() => setIsPopUpOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen}
        style={{ maxHeight: 750, direction: 'rtl' }}
        className="add-driver-modal undelivered-messages-modal"
      >
        {isLoading ? (
          <div style={{ padding: 40 }}>
            <ProcessingLoader />
          </div>
        ) : (
          <>
            {payload?.length > 0 && (
              <div style={{ display: 'flex', justifyContent: 'left', padding: 5 }}>
                <Button
                  style={{ margin: 5, backgroundColor: '#22af47', color: 'white' }}
                  variant="contained"
                  disabled={isLoading}
                  endIcon={<WhatsAppIcon style={{ marginRight: 7 }} />}
                  onClick={handleUltraBulkMessages}
                >
                  ارسال المحدد
                </Button>
              </div>
            )}
            <DialogContent dividers>
              <MaterialDataGrid
                data={data}
                columns={columns}
                options={options}
                actions={actions}
                onSelectionChange={onSelectionChange}
              />
            </DialogContent>
          </>
        )}
        <DialogActions style={{ padding: '8px 24px' }}>
          <Button
            style={{ backgroundColor: '#006782', color: 'white', margin: 5 }}
            variant="contained"
          >
            اجمالي الرسائل: {data?.length}
          </Button>
          <Button
            autoFocus
            style={{ backgroundColor: 'gray', color: 'white', margin: 5 }}
            onClick={() => setIsPopUpOpen(false)}
          >
            الغاء
          </Button>
        </DialogActions>
      </Dialog>
    </AppErrorBoundary>
  );
};

export default UndeliveredModal;
