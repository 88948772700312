import React, { useState } from 'react';
import MaterialDataGrid from '../../components/shared/MaterialDataGrid';
import { flagIcon } from '../../constants/Helpers';
import EditInviteeModal from '../modals/EditInviteeModal';
import DeleteInviteeModal from '../modals/DeleteInviteeModal';
import UndeliveredModal from '../modals/UndeliveredModal';
import { toast } from 'react-toastify';
//material ui
import TableContainer from '@material-ui/core/TableContainer';
import CustomSelect from '../shared/CustomSelect';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Button } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
//shared
import AppErrorBoundary from '../shared/AppErrorBoundary';
//api
import AppService from '../../services/AppService';

const InviteesComponent = ({
  inviteesList,
  setSelectedInvitees,
  setOccasionDetails,
  clonedOccasionDetails,
  fetchOccasionById,
  occasionId,
  selectedCount,
  setSelectedCount,
  isReminder,
  brochureUrl,
  setBrochureType,
  brochureType,
}) => {
  const [typeValue, setTypeValue] = useState(0);
  const [selectedInv, setSelectedInv] = useState('');
  const [isPopupOpen, setIsPopUpOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isUndeliveredPopupOpen, setIsUndeliveredPopupOpen] = useState(false);

  const handleConfirm = async (rowData) => {
    const dataBody = {
      accept: rowData?.isRejected ? true : false,
    };
    try {
      await AppService.getConfirmAttend(dataBody, rowData?.id);
      setTimeout(() => {
        fetchOccasionById(occasionId);
      }, 200);
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'حدث خطأ');
    }
  };

  const columns = [
    {
      title: 'الاسم',
      field: 'name',
    },
    {
      title: 'رقم التليفون',
      field: 'phone',
    },
    {
      title: 'عدد الدعوات',
      render: (rowData) => rowData?.count,
    },
    {
      title: 'رسالة الترحيب',
      render: (rowData) =>
        rowData?.isWhatsappSent ? (
          <img src={flagIcon.trueIcon} style={{ width: 33, marginRight: 5 }} />
        ) : (
          <img src={flagIcon.falseIcon} style={{ width: 33, marginRight: 5 }} />
        ),
    },
    {
      title: 'رسالة البرشور ',
      render: (rowData) =>
        rowData?.isBrochureSent ? (
          <img src={flagIcon.trueIcon} style={{ width: 33, marginRight: 5 }} />
        ) : (
          <img src={flagIcon.falseIcon} style={{ width: 33, marginRight: 5 }} />
        ),
    },
    {
      title: 'الحالة',
      render: (rowData) => (
        <span>{!rowData?.isTokenUsed ? 'غير محدد' : rowData?.isRejected ? 'معتذر' : 'تأكيد'}</span>
      ),
    },
    {
      title: 'لينك الاعتذار',
      render: (rowData) => (
        <a href={`https://dawabarcode.com/apologize/${rowData?.token}`} target="_blank">
          افتح
        </a>
      ),
    },
    // {
    //   title: 'تم الاعتذار',
    //   render: (rowData) => (rowData?.isRejected ? 'نعم' : 'لا'),
    // },
    {
      title: 'الباركود ',
      render: (rowData) => barCodeAttachments(rowData),
    },
    {
      title: 'تعديل ',
      render: (rowData) => (
        <>
          <Button
            style={{ backgroundColor: '#006782', color: 'white', margin: 3 }}
            variant="contained"
            size="small"
            onClick={() => {
              setIsPopUpOpen(true);
              setSelectedInv(rowData);
            }}
          >
            تعديل
          </Button>
          <Button
            style={{ backgroundColor: '#F50157', color: 'white' }}
            variant="contained"
            size="small"
            onClick={() => {
              setIsDeletePopupOpen(true);
              setSelectedInv(rowData);
            }}
          >
            حذف
          </Button>
          <Button
            style={{
              backgroundColor: rowData?.isRejected ? '#22af47' : 'gray',
              color: 'white',
              marginTop: 3,
            }}
            variant="contained"
            size="small"
            onClick={() => {
              handleConfirm(rowData);
            }}
          >
            {rowData?.isRejected ? 'تاكيد' : 'اعتذار'}
          </Button>
        </>
      ),
    },
  ];

  const barCodeAttachments = (rowData) => {
    return rowData?.qrCodes?.map((el, i) => (
      <a
        key={i}
        style={{ display: 'block' }}
        href={el?.brochureQrCodeUrl}
        target="_blank"
        rel="noreferrer"
        download
      >
        {el?.qrCode}
      </a>
    ));
  };

  const options = {
    paging: false,
    showTitle: false,
    columnsButton: false,
    sorting: false,
    exportButton: false,
    selection: true,
  };

  //actions
  const actions = [
    {
      icon: () => (
        <Box className="invitees-tab-wrapper">
          <Tabs
            indicatorColor="primary"
            textColor="secondary"
            value={typeValue}
            onChange={handleChangeType}
            centered
          >
            <Tab
              label={
                <>
                  <span>الاجمالي</span>{' '}
                  <p>
                    {clonedOccasionDetails?.invitees?.flatMap((entery) => entery?.qrCodes)?.length}
                  </p>
                </>
              }
            />
            <Tab
              label={
                <>
                  <span>الحضور</span> <p>{getAttendeceCount()}</p>
                </>
              }
            />
            <Tab
              label={
                <>
                  <span>تأكيد</span>
                  <p>{getTotalConfiremed()}</p>
                </>
              }
            />
            <Tab
              label={
                <>
                  <span>معتذر</span> <p>{getTotalApologized()}</p>
                </>
              }
            />
            <Tab
              label={
                <>
                  <span>غير محدد </span> <p>{getNotSpecified()}</p>
                </>
              }
            />
            <Tab
              label={
                <>
                  <span>تم الارسال</span>{' '}
                  <p>
                    {clonedOccasionDetails?.invitees?.filter((el) => el?.isWhatsappSent)?.length}
                  </p>
                </>
              }
            />
            <Tab
              label={
                <>
                  <span>لم يتم الارسال</span>{' '}
                  <p>
                    {clonedOccasionDetails?.invitees?.filter((el) => !el.isWhatsappSent)?.length}
                  </p>
                </>
              }
            />
          </Tabs>
        </Box>
      ),
      isFreeAction: true,
    },
  ];

  const onSelectionChange = (selectedrecords) => {
    setSelectedInvitees(selectedrecords);
  };

  const handleChangeType = (e, newValue) => {
    setTypeValue(newValue);
    if (newValue === 0) setOccasionDetails(clonedOccasionDetails);
    if (newValue === 1)
      setOccasionDetails({
        ...clonedOccasionDetails,
        invitees: clonedOccasionDetails?.invitees?.filter((el) => el.isAttended === true),
      });
    else if (newValue === 2)
      setOccasionDetails({
        ...clonedOccasionDetails,
        invitees: clonedOccasionDetails?.invitees?.filter(
          (el) => el.isRejected === false && el?.isTokenUsed === true
        ),
      });
    else if (newValue === 3)
      setOccasionDetails({
        ...clonedOccasionDetails,
        invitees: clonedOccasionDetails?.invitees?.filter((el) => el.isRejected === true),
      });
    else if (newValue === 4)
      setOccasionDetails({
        ...clonedOccasionDetails,
        invitees: clonedOccasionDetails?.invitees?.filter((el) => !el.isTokenUsed),
      });
    else if (newValue === 5)
      setOccasionDetails({
        ...clonedOccasionDetails,
        invitees: clonedOccasionDetails?.invitees?.filter((el) => el.isWhatsappSent === true),
      });
    else if (newValue === 6) {
      setOccasionDetails({
        ...clonedOccasionDetails,
        invitees: clonedOccasionDetails?.invitees?.filter((el) => el.isWhatsappSent === false),
      });
    }
  };

  const getAttendeceCount = () => {
    const attendanceEntries =
      clonedOccasionDetails?.invitees
        ?.filter((el) => el?.isAttended === true)
        ?.flatMap((entry) => entry?.qrCodes) ?? [];
    const totalLength = attendanceEntries?.filter((invitee) => invitee?.isQrCodeUsed)?.length;
    return totalLength;
  };

  const getTotalConfiremed = () => {
    const rejectedEntries = clonedOccasionDetails?.invitees?.filter(
      (el) => el.isRejected === false && el?.isTokenUsed === true
    );
    const totalLength = rejectedEntries?.reduce((total, entry) => total + entry.qrCodes.length, 0);
    return totalLength;
  };

  const getTotalApologized = () => {
    const rejectedEntries = clonedOccasionDetails?.invitees?.filter((el) => el.isRejected === true);
    const totalLength = rejectedEntries?.reduce((total, entry) => total + entry.qrCodes.length, 0);
    return totalLength;
  };

  const getNotSpecified = () => {
    const notSpecifiedUseers = clonedOccasionDetails?.invitees?.filter((el) => !el.isTokenUsed);
    const totalLength = notSpecifiedUseers?.reduce(
      (total, entry) => total + entry.qrCodes.length,
      0
    );
    return totalLength;
  };

  return (
    <>
      <AppErrorBoundary>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 8,
            overflowX: 'auto',
          }}
        >
          <Button
            style={{ backgroundColor: '#22af47', color: 'white', height: 40 }}
            variant="contained"
            onClick={() => setIsPopUpOpen(true)}
          >
            اضافة مدعو جديد
          </Button>
          {inviteesList?.length > 0 && (
            <Button
              style={{ backgroundColor: '#006782', color: 'white', height: 40 }}
              variant="contained"
              onClick={() => setIsUndeliveredPopupOpen(true)}
              startIcon={<WarningIcon style={{ marginLeft: 8, color: 'white' }} />}
            >
              الارقام الغير مرسلة
            </Button>
          )}{' '}
          {!isReminder && (
            <div style={{ display: 'flex' }}>
              <div style={{ width: 200, marginLeft: 7 }}>
                <div>الفلتر</div>
                <CustomSelect
                  listData={[
                    { id: 0, name: '0' },
                    { id: 5, name: '5' },
                    { id: 10, name: '10' },
                    { id: 15, name: '15' },
                    { id: 20, name: '20' },
                    { id: 25, name: '25' },
                    { id: 30, name: '30' },
                    { id: 40, name: '40' },
                    { id: 50, name: '50' },
                  ]}
                  handleChange={(value) => setSelectedCount(value?.id)}
                  optionValue="id"
                  optionName="name"
                  placeholder="تحديد الارسال"
                  selectedValue={selectedCount}
                  disableClearIcon
                />
              </div>
              {brochureUrl && (
                <div style={{ width: 200 }}>
                  <div>تحديد الارسال</div>
                  <CustomSelect
                    listData={[
                      { id: 0, name: 'الكل' },
                      { id: 1, name: 'المؤكد فقط' },
                      { id: 2, name: 'الغير محدد فقط' },
                    ]}
                    handleChange={(value) => setBrochureType(value?.id)}
                    optionValue="id"
                    optionName="name"
                    placeholder="تحديد الارسال"
                    selectedValue={brochureType}
                    disableClearIcon
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <TableContainer component={Paper}>
          <MaterialDataGrid
            data={inviteesList}
            columns={columns}
            options={options}
            actions={actions}
            onSelectionChange={onSelectionChange}
          />
        </TableContainer>
        {isPopupOpen && (
          <EditInviteeModal
            selectedInv={selectedInv}
            occasionId={clonedOccasionDetails?.id}
            isPopupOpen={isPopupOpen}
            setIsPopUpOpen={setIsPopUpOpen}
            fetchOccasionById={fetchOccasionById}
            setSelectedInv={setSelectedInv}
          />
        )}
        {isDeletePopupOpen && (
          <DeleteInviteeModal
            selectedInv={selectedInv}
            occasionId={clonedOccasionDetails?.id}
            isDeletePopupOpen={isDeletePopupOpen}
            setIsDeletePopupOpen={setIsDeletePopupOpen}
            fetchOccasionById={fetchOccasionById}
            setSelectedInv={setSelectedInv}
          />
        )}
        {isUndeliveredPopupOpen && (
          <UndeliveredModal
            occasionId={clonedOccasionDetails?.id}
            isPopupOpen={isUndeliveredPopupOpen}
            setIsPopUpOpen={setIsUndeliveredPopupOpen}
          />
        )}
      </AppErrorBoundary>
    </>
  );
};

export default InviteesComponent;
